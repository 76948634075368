import { createRouter, createWebHistory, NavigationGuardNext, RouteRecordName, RouteRecordRaw } from "vue-router";
import store from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Private",
    component: () => import("@/layouts/Private"),
    children: [
      {
        path: "/password-change",
        component: () => import("@/views/authentication/Password/PasswordChange"),
      },
      {
        path: "/dashboard",
        component: () => import("@/views/dashboard/Dashboard"),
      },
      {
        path: "",
        redirect: "/dashboard",
      },
      {
        path: "employees",
        component: () => import("@/views/emp/EmpList"),
      },
      {
        path: "insights",
        name: "insights",
        component: () => import("@/views/insight/Insight"),
      },
      {
        path: "company",
        component: () => import("@/layouts/Company"),
        children: [
          {
            path: "details",
            component: () => import("@/views/company/CompanyDetails"),
          },
          {
            path: "",
            redirect: "/company/details",
          },
          {
            path: "locations",
            component: () => import("@/views/company/CompanyLocations"),
          },
        ],
      },
    ],
  },
  {
    path: "/sponsorships",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/views/sponsorship/Sponsorships"),
      },
      {
        path: ":id",
        component: () => import("../layouts/Sponsorship"),
        children: [
          {
            path: "details",
            component: () => import("../views/sponsorship/details/SponsorshipDetails"),
          },
          {
            path: "activity",
            component: () => import("../views/sponsorship/activity/Activity"),
          },
          {
            path: "coupon",
            component: () => import("@/views//sponsorship/coupon/Coupon"),
          },
          {
            path: "planning",
            component: () => import("../views/sponsorship/planning/Planning"),
          },
          {
            path: "audience",
            component: () => import("../views/sponsorship/audience/Audience"),
          },
          {
            path: "delivery",
            component: () => import("../views/sponsorship/delivery/Delivery"),
            children: [
              {
                path: "direction",
                component: () => import("../views/sponsorship/delivery/direction/Directions"),
              },
              {
                path: "zone",
                component: () => import("../views/sponsorship/delivery/zone/Zones"),
              },
            ],
          },
          {
            path: "participants",
            component: () => import("../views/sponsorship/participants/Participants"),
          },
          {
            path: "referral-code",
            component: () => import("../views/sponsorship/referral-code/ReferralCode"),
          },
          {
            path: "survey-result",
            component: () => import("../views/sponsorship/survey-result/SurveyResult"),
          },
          {
            path: "cta",
            component: () => import("../views/sponsorship/cta/SponsorshipCTA"),
          },
          {
            path: "video-capture",
            component: () => import("../views/sponsorship/video-capture/VideoCapture"),
          },
        ],
      },
    ],
  },
  {
    path: "/instant-insights",
    component: () => import("../layouts/Private"),
    children: [
      {
        path: "",
        component: () => import("@/views/insight-sponsorship/Sponsorships"),
      },
      {
        path: ":id",
        component: () => import("../layouts/InsightSponsorship"),
        children: [
          {
            path: "details",
            component: () => import("../views/insight-sponsorship/details/SponsorshipDetails"),
          },
          {
            path: "activity",
            component: () => import("../views/insight-sponsorship/activity/Activity"),
          },
          {
            path: "coupon",
            component: () => import("@/views//insight-sponsorship/coupon/Coupon"),
          },
          {
            path: "planning",
            component: () => import("../views/insight-sponsorship/planning/Planning"),
          },
          {
            path: "audience",
            component: () => import("../views/insight-sponsorship/audience/Audience"),
          },
          {
            path: "delivery",
            component: () => import("../views/insight-sponsorship/delivery/Delivery"),
            children: [
              {
                path: "direction",
                component: () => import("../views/insight-sponsorship/delivery/direction/Directions"),
              },
              {
                path: "zone",
                component: () => import("../views/insight-sponsorship/delivery/zone/Zones"),
              },
            ],
          },
          {
            path: "participants",
            component: () => import("../views/insight-sponsorship/participants/Participants"),
          },
          {
            path: "referral-code",
            component: () => import("../views/insight-sponsorship/referral-code/ReferralCode"),
          },
          {
            path: "survey-result",
            component: () => import("../views/insight-sponsorship/survey-result/SurveyResult"),
          },
          {
            path: "cta",
            component: () => import("../views/insight-sponsorship/cta/SponsorshipCTA"),
          },
          {
            path: "video-capture",
            component: () => import("../views/insight-sponsorship/video-capture/VideoCapture"),
          },
          {
            path: "app-messages",
            component: () => import("../views/insight-sponsorship/app-message/AppMessage"),
          },
        ],
      },
    ],
  },
  {
    path: "/qr",
    name: "DisplayQR",
    component: () => import("@/views/qr/QRCode"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("@/views/welcome/Welcome"),
  },
  {
    path: "/password-reset",
    name: "ForgotPassword",
    component: () => import("@/views/authentication/Password/ForgotPassword"),
  },
  {
    path: "/signin",
    name: "Signin",
    component: () => import("@/views/authentication/Signin"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/authentication/Signup"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const redirectCondition = (to: RouteRecordName | null | undefined, name: string, next: NavigationGuardNext) => {
  if (!to) {
    return;
  }

  const isTargetName = to === name;

  if (isTargetName && !store.state.auth.isAuthenticated) {
    return next();
  }

  if (isTargetName && store.state.auth.isAuthenticated) {
    return next({ name: "Private" });
  }
};

router.beforeEach((to, from, next) => {
  redirectCondition(to?.name, "ForgotPassword", next);
  redirectCondition(to?.name, "Welcome", next);

  if (to?.name === "DisplayQR") {
    return next();
  }

  if (to?.name === "Signup") {
    return next();
  }

  redirectCondition(to?.name, "Signin", next);
  if (to?.name !== "Signin" && !store.state.auth.isAuthenticated) {
    return next("signin");
  }

  next();
});

export default router;
