import { createApp } from "vue";
import uikit from "@twjs/vue";
import loading from "@twjs/vue-loading";
import notice from "@twjs/vue-notice";
import { App } from "./App";
import router from "./router";
import store from "./store";
import auth from "./plugins/auth";
import http from "./plugins/http";

import { TextArea } from "@/components/TextArea";
import { Input } from "./components/Input";
import { Select } from "./components/Select";
import { FilterSelect } from "./components/FilterSelect";
import { TagPicker } from "./components/TagPicker";
import { Tab } from "./components/Tab";
import { Tooltip } from "./components/Tooltip";
import { ToogleSwitch } from "./components/ToogleSwitch";
import { Checkbox } from "./components/Checkbox";
import { CommaTextbox } from "./components/CommaTextbox";
import { Radio } from "./components/Radio";
import { RangeSlider } from "./components/RangeSlider";

import "./registerServiceWorker";
import "./assets/index.scss";

const app = createApp(App)
  .use(uikit)
  .use(store)
  .use(router)
  .use(loading)
  .use(notice)
  .use(auth, { endpoint: process.env.VUE_APP_AUTH_ENDPOINT })
  .use(http, { endpoint: process.env.VUE_APP_HTTP_ENDPOINT })
  .component("pw-radio", Radio)
  .component("pw-input", Input)
  .component("pw-select", Select)
  .component("pw-fselect", FilterSelect)
  .component("pw-tagpicker", TagPicker)
  .component("pw-tab", Tab)
  .component("pw-tooltip", Tooltip)
  .component("pw-switch", ToogleSwitch)
  .component("pw-checkbox", Checkbox)
  .component("text-area", TextArea)
  .component("comma-textbox", CommaTextbox)
  .component("range-slider", RangeSlider)
  .mount("#app");
