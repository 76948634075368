import { ChangePassword } from './../types/auth';
import { get, set, remove } from "js-cookie";
import decode from "jwt-decode";
import { Account, AuthState } from "@/types/auth";

const DEFAULT_TOKEN_KEY = "auth.token";
const DEFAULT_LOGIN_URL = "/customer/login";
const DEFAULT_SIGNUP_URL = "/customer/signup";

export default {
  install: (app: any, options: any) => {
    const $store = app.config.globalProperties.$store;
    const $router = app.config.globalProperties.$router;
    const auth = $store.state.auth as AuthState;

    const token = get(DEFAULT_TOKEN_KEY);
    if (!!token && token !== "") {
      const user = decode<any>(token);
      $store.commit("setAuth", {
        ...auth,
        ...{
          isAuthenticated: !!token && token !== "",
          token: token,
          user: {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            company: {
              id: user.company_id,
            },
            profile: user.profile
          },
        },
      });
    }

    app.mixin({
      mutations: {
        setAuth: (state: any, auth: AuthState) => {
          state.auth = auth;
        },
      },
    });

    app.config.globalProperties.$auth = {
      login: async (account: Account): Promise<void | Response> => {
        try {
          const response = await fetch(`${options.endpoint}${DEFAULT_LOGIN_URL}`, {
            method: "POST",
            headers: {
              Authorization: "Basic cGF5dHJpcHBhOlBheXRyaXBwYUAyMDIw",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: account.username,
              password: account.password,
            }),
          });
          if (!response.ok) {
            return response;
          }
          const token = (await response.json()).data.token;

          set(DEFAULT_TOKEN_KEY, token);

          if (!!token && token !== "") {
            const user = decode<any>(token);
            $store.commit("setAuth", {
              ...auth,
              ...{
                isAuthenticated: !!token && token !== "",
                token: token,
                user: {
                  id: user.id,
                  firstName: user.first_name,
                  lastName: user.last_name,
                  company: {
                    id: user.company_id,
                  },
                  profile: user.profile
                },
              },
            });
          }
          setTimeout(() => {
            $router.push("/");
          }, 750)
        } catch (e: any) {
          console.error(e);
        }
      },

      async signup(account: any): Promise<Response> {
        return await fetch(`${options.endpoint}${DEFAULT_SIGNUP_URL}`, {
          method: "POST",
          headers: {
            Authorization: "Basic cGF5dHJpcHBhOlBheXRyaXBwYUAyMDIw",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(account),
        });
      },

      async forgot(email: string): Promise<Response> {
        return await fetch(`${options.endpoint}/customer/forgot-password`, {
          method: "POST",
          headers: {
            Authorization: "Basic cGF5dHJpcHBhOlBheXRyaXBwYUAyMDIw",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        });
      },
      async changePassword(data: ChangePassword): Promise<Response> {
        return await fetch(`${options.endpoint}/customer/change-password`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${get(DEFAULT_TOKEN_KEY)}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      },
      logout: (): void => {
        $store.commit("setAuth", { isAuthenticated: false });
        remove(DEFAULT_TOKEN_KEY);
        $router.push("/signin");
      },
    };
  },
};
