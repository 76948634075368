import { Loading } from "@twjs/vue-loading";
import { Notice } from "@twjs/vue-notice";
import { defineComponent, PropType, provide } from "vue";
import { RouterView } from "vue-router";
import "./assets/tailwind.css";
import { Http } from "./types/http";

export const App = defineComponent({
  props: {
    $http: { type: Object as PropType<Http>, required: true },
    $loading: { type: Object as PropType<Loading>, required: true },
    $notice: { type: Object as PropType<Notice>, required: true },
  },
  mounted() {
    provide("$http", this.$http);
    provide("$loading", this.$loading);
    provide("$notice", this.$notice);
  },
  render() {
    return (
      <>
        <w-spinner />
        <w-notice />
        <RouterView />
      </>
    );
  },
});
