import { createStore } from "vuex";

export default createStore({
  state: {
    auth: {
      isAuthenticated: false,
    },
    files: [
      {
        code: "",
        link: "",
      },
    ],
    codeUsages: {
      id: -9999,
      data: [],
      page: 1,
      lastPage: 1,
    },
    code: {
      id: -9999,
      code: "",
    },
    // Save prev state when redirect to other page,
    // Use to keep current state when back to page
    prevState: {
      sponsorships: {
        filters: {
          name: "",
          status: "-2",
          id: "",
        },
        page: 1,
        sort: {
          field: "",
          type: "",
        },
      },
      sponsorshipParticipant: {
        id: "",
        name: "",
        startDate: "",
        endDate: "",
      },
    },
    insightPrevState: {
      sponsorships: {
        filters: {
          name: "",
          status: "-2",
          id: "",
        },
        page: 1,
        sort: {
          field: "",
          type: "",
        },
      },
      sponsorshipParticipant: {
        id: "",
        name: "",
        startDate: "",
        endDate: "",
      },
    },
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth;
    },
    setFiles(state, file) {
      state.files = [...state.files, file];
    },
    setCodeUsages(state, usageData) {
      state.codeUsages = { ...usageData };
    },
    setCode(state, code) {
      state.code = { ...code };
    },
    setPrevState(state, newState) {
      state.prevState = { ...state.prevState, ...newState };
    },
    setinsighPrevState(state, newState) {
      state.prevState = { ...state.insightPrevState, ...newState };
    },
  },
  actions: {},
  modules: {},
});
