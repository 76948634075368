import { defineComponent, PropType } from "vue";

export const Input = defineComponent({
  name: "Input",
  emits: ["update:modelValue", "change"],
  props: {
    type: { type: String },
    name: { type: String },
    label: { type: String },
    placeholder: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    modelValue: { type: [String, Number] },
    prefix: { type: String },
    onRenderLabel: { type: Function },
    onRenderIcon: { type: Function },
    error: { type: Object as PropType<{ message: string; type?: string }> },
    useOnInput: { type: Boolean, defaultValue: false },
  },
  data() {
    return { isFocus: false };
  },
  render() {
    return (
      <div class="w-full flex flex-col">
        {this.onRenderLabel ? (
          this.onRenderLabel(this.$props)
        ) : this.label ? (
          <label class="flex flex-start mb-2">
            <span class="text-default-600 font-semibold text-sm">{this.label}</span>
            {this.required && <span class="text-red-600">&nbsp;*</span>}
          </label>
        ) : null}
        <div
          tabindex={-1}
          class={`flex flex-row w-full h-12 border border-solid ${
            this.isFocus ? "border-primary-600" : "border-gray-300"
          } outline-none rounded`}
        >
          {this.prefix && <div class="bg-default-100 text-sm p-4 rounded-tl rounded-bl">{this.prefix}</div>}
          <input
            class="w-full h-full px-4 outline-none rounded"
            type={this.type || "text"}
            name={this.name}
            value={this.modelValue}
            placeholder={this.placeholder}
            onChange={(e: Event) => !this.useOnInput && this.$emit("update:modelValue", (e.target as HTMLInputElement).value)}
            onInput={(e: Event) => this.useOnInput && this.$emit("update:modelValue", (e.target as HTMLInputElement).value)}
            disabled={this.disabled}
            readonly={this.readonly}
            onFocus={() => {
              this.isFocus = true;
            }}
            onBlur={() => {
              this.isFocus = false;
            }}
          />
          {this.onRenderIcon?.()}
        </div>
        {this.error && <div class="text-sm text-red-600 text-left mt-2">{this.error.message}</div>}
      </div>
    );
  },
});
