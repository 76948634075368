import { defineComponent, PropType } from "vue";

export const Select = defineComponent({
  name: "pw-select",
  emits: ["update:modelValue"],
  props: {
    options: { type: Object as PropType<Array<{ key: string; text: string }>>, required: true },
    name: { type: String },
    tabIndex: { type: Number },
    modelValue: { type: String, defaultValue: "" },
    label: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    error: { type: Object as PropType<{ message: string; type?: string }> },
  },
  data() {
    return { isFocus: false, current: "" };
  },
  computed: {
    text(): string {
      if (!this.modelValue && this.modelValue !== "") {
        return "";
      }
      return this.options.filter((o) => o.key === this.modelValue)[0]?.text ?? "";
    },
  },
  render() {
    return (
      <div class="w-full">
        {this.label && (
          <label class="flex flex-start mb-2">
            <span class="text-default-600 font-semibold text-sm">{this.label}</span>
            {this.required && <span class="text-red-600">&nbsp;*</span>}
          </label>
        )}
        <div
          class={`relative border border-solid ${this.isFocus ? "border-primary-600" : "border-gray-300"} ${
            this.disabled ? "bg-default-100" : "bg-white"
          } rounded w-full h-12 outline-none`}
          tabindex={this.tabIndex ?? -1}
          onClick={() => {
            if (this.disabled) {
              return;
            }
            if (this.readonly) {
              this.isFocus = true;
              return;
            }
            this.isFocus = !this.isFocus;
          }}
          onBlur={() => (this.isFocus = false)}
        >
          <i
            class="ms-Icon ms-Icon--ChevronDown absolute"
            style={{
              top: "1rem",
              right: "1rem",
              transition: "all 1s cubic-bezier(0.075, 0.82, 0.165, 1)",
              transform: this.isFocus && !this.readonly ? "rotate(180deg)" : "unset",
            }}
          />
          <div class="text-left text-sm px-4 py-4">{this.text}</div>
          <ul
            class="w-full absolute py-4"
            style={{
              display: this.isFocus && !this.readonly ? "block" : "none",
              top: "3.5rem",
              left: 0,
              right: 0,
              backgroundColor: "#fff",
              boxShadow: "5px 8px 14px 0 rgba(0, 0, 0, 0.17)",
              zIndex: 1000,
            }}
          >
            {this.options.map((o) => (
              <li
                key={o.key}
                class="h-10 leading-normal px-4 py-2 text-left hover:bg-gray-100 relative"
                onClick={() => {
                  this.$emit("update:modelValue", o.key);
                  setTimeout(() => (this.isFocus = false), 1000);
                }}
              >
                <span>{o.text}</span>
                {!!this.modelValue && this.modelValue !== "" && o.key === this.modelValue && (
                  <i
                    class="ms-Icon ms-Icon--CheckMark absolute text-primary-600 leading-none"
                    style={{ top: "0.75rem", right: "0.75rem" }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
        {this.error && <div class="text-sm text-red-600 text-left mt-2">{this.error.message}</div>}
      </div>
    );
  },
});
