import { defineComponent, PropType, reactive, toRefs, watch } from "vue";

export interface Tag {
  key: string;
  text: string;
}

export const TagPicker = defineComponent({
  name: "pw-tagpicker",
  emits: ["update:modelValue"],
  props: {
    options: { type: Object as PropType<Array<Tag>>, required: true },
    name: { type: String },
    tabIndex: { type: Number },
    modelValue: { type: Array as PropType<string[]>, required: true, defaultValue: [] },
    label: { type: String },
    placeholder: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    onAddTag: { type: Function as PropType<(tag: string, modelValue: string[], options?: Tag[]) => string[]>, required: false },
    onRemoveTag: { type: Function as PropType<(key: string, modelValue: string[], options?: Tag[]) => string[]>, required: false },
    error: { type: Object as PropType<{ message: string; type?: string }> },
  },
  data() {
    return { isFocus: false, topPosition: "3.5rem" };
  },
  updated() {
    const tagHeight = (this.$refs.tags as HTMLDivElement).offsetHeight;
    this.topPosition = tagHeight ? tagHeight / 16 + 0.5 + "rem" : "3.5rem";
  },
  methods: {
    onTagClick(o: { text: string; key: string }) {
      if (this.modelValue.includes(o.key)) {
        this.removeTag(o.key);
        return;
      }

      this.addTag(o.key);
    },
    addTag(key: string) {
      if (this.onAddTag) {
        const data = this.onAddTag(key, this.modelValue, this.options);
        this.$emit("update:modelValue", [...data]);

        return;
      }

      if (key === "-1") {
        this.$emit("update:modelValue", ["-1"]);

        return;
      }

      if (this.modelValue.includes("-1")) {
        const data = this.options.map((item) => item.key).filter((item) => item !== key && item !== "-1");
        this.$emit("update:modelValue", [...data]);

        return;
      }

      const data = this.options.map((item) => item.key).filter((item) => item !== "-1");

      const isAll = [...data].every((item) => [...this.modelValue, key].includes(item));

      if (isAll) {
        this.$emit("update:modelValue", ["-1"]);
        return;
      }

      this.$emit("update:modelValue", [...this.modelValue, key]);
    },
    removeTag(key: string) {
      if (this.readonly || this.disabled) {
        return;
      }

      if (this.onRemoveTag) {
        const data = this.onRemoveTag(key, this.modelValue, this.options);
        this.$emit("update:modelValue", [...data]);

        return;
      }

      if (this.modelValue.includes("-1") && key !== "-1") {
        const data = this.options.map((item) => item.key).filter((item) => item !== key && item !== "-1");
        this.$emit("update:modelValue", [...data]);

        return;
      }

      const modelValue = this.modelValue.filter((tag) => tag !== key);
      this.$emit("update:modelValue", [...modelValue]);
    },
    getTextFromKey(id: string) {
      return this.options.filter((item) => item.key === id)?.[0]?.text ?? undefined;
    },
  },
  render() {
    return (
      <div class="w-full">
        {this.label && (
          <label class="flex flex-start mb-2">
            <span class="text-default-600 font-semibold text-sm">{this.label}</span>
            {this.required && <span class="text-red-600">&nbsp;*</span>}
          </label>
        )}
        <div
          tabindex={this.tabIndex ?? -1}
          class={`relative border border-solid ${this.isFocus ? "border-primary-600" : "border-gray-300"} ${
            this.disabled ? "bg-default-100" : ""
          } rounded w-full outline-none`}
          onClick={() => {
            if (this.disabled) {
              return;
            }

            if (this.readonly) {
              this.isFocus = true;
              return;
            }

            this.isFocus = !this.isFocus;
          }}
          onBlur={() => (this.isFocus = false)}
        >
          <div class="flex flex-wrap text-left text-sm px-2 pb-2 text-gray min-h-12" ref="tags">
            {this.modelValue.includes("-1")
              ? this.options
                  .filter((item) => item.key !== "-1")
                  .map((item) =>
                    item.text ? (
                      <span class="block tag px-2 py-1 border rounded-sm border-solid mt-2 mr-2 flex items-center text-gray">
                        {item.text}
                        <i
                          class="ms-Icon ms-Icon--Cancel font-bold ml-2 cursor-pointer p-1"
                          style={{
                            position: "initial",
                            height: "auto",
                            width: "auto",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.removeTag(item.key);
                          }}
                        />
                      </span>
                    ) : null,
                  )
              : this.modelValue.map((item) =>
                  this.getTextFromKey(item) ? (
                    <span class="block tag px-2 py-1 border rounded-sm border-solid mt-2 mr-2 flex items-center text-gray">
                      {this.getTextFromKey(item)}
                      <i
                        style={{
                          position: "initial",
                          height: "auto",
                          width: "auto",
                        }}
                        class="ms-Icon ms-Icon--Cancel font-bold ml-2 cursor-pointer p-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.removeTag(item);
                        }}
                      />
                    </span>
                  ) : null,
                )}
          </div>
          <ul
            class="w-full py-4 absolute"
            style={{
              display: this.isFocus && !this.readonly ? "block" : "none",
              top: this.topPosition,
              left: 0,
              right: 0,
              backgroundColor: "#fff",
              boxShadow: "5px 8px 14px 0 rgba(0, 0, 0, 0.17)",
              zIndex: 1000,
              maxHeight: "250px",
              overflow: "auto",
            }}
          >
            {this.options.map((o, index) => (
              <li
                key={o.key}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onTagClick(o);
                }}
                class="h-10 leading-normal px-4 py-2 text-left hover:bg-gray-100 relative cursor-pointer"
              >
                <label class="checkbox-container relative" for={`${o.key + index}`}>
                  {o.text}
                  <input
                    id={o.key + index}
                    type="checkbox"
                    checked={this.modelValue.includes(o.key) || this.modelValue.includes("-1")}
                    onChange={() => {
                      this.onTagClick(o);
                    }}
                  />
                  <span class="checkmark absolute" />
                </label>
              </li>
            ))}
          </ul>
        </div>
        {this.error && <div class="text-sm text-red-600 text-left mt-2">{this.error.message}</div>}
      </div>
    );
  },
});
