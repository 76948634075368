import { defineComponent, PropType } from "vue";

export const Tooltip = defineComponent({
  name: "Tooltip",
  props: {
    text: { type: String },
    class: { type: String },
    position: { type: String },
  },
  render() {
    return (
      <div class={`relative tooltip ${this.class}`} flow-position={this.position} tooltip-text={this.text}>
        {this.$slots.default ? this.$slots.default() : null}
      </div>
    );
  },
});
