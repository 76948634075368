import { defineComponent, PropType } from "vue";

export const Checkbox = defineComponent({
  name: "Checkbox",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
    },
    label: {
      type: String,
      defaultValue: "",
    },
    controlClass: {
      type: String,
      defaultValue: "",
    },
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    value: {
      type: [String, Number],
    },
    onChange: {
      type: Function as PropType<(value: any) => void>,
      require: false,
    },
    isChecked: {
      type: Object as PropType<{ value: boolean }>,
    },
  },
  methods: {
    handleChange(event: any) {
      if (this.onChange && this.value) {
        this.onChange(this.value);
      }

      if (this.value) {
        this.$emit("update:modelValue", event.target.checked);
        return;
      }

      this.$emit("update:modelValue", event.target.checked);
    },
  },
  render() {
    return (
      <label class={`checkbox-container relative font-semibold ${this.controlClass}`}>
        {this.label}
        <input
          type="checkbox"
          checked={this.isChecked ? this.isChecked.value : this.modelValue}
          onChange={this.handleChange}
          disabled={this.disabled}
        />
        <span class="checkmark absolute" />
      </label>
    );
  },
});
